import React from 'react'
import { styled } from '@mui/material/styles'
import Link from 'next/link'
import { Container, Grid, Link as MaterialUiLink, Typography } from '@mui/material/'
import { StyledTextA } from '@components/atoms/StyledTextA'

const PREFIX = 'Footer'

const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
}

const StyledStyledTextA = styled(StyledTextA)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.grey[200],
    overflow: 'hidden',
    paddingTop: theme.spacing(2),
  },

  [`& .${classes.link}`]: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export default () => (
  <StyledStyledTextA className={classes.root}>
    <Container>
      <Grid container={true} spacing={5}>
        <Grid item xs={12} sm={12} md={3}>
          <Grid container={true} direction="column" justifyContent="flex-start" spacing={2}>
            <Grid item={true}>
              <img style={{ width: '120px' }} src="/img/logo.png" alt="COATAZ" />
            </Grid>
            <Grid item={true}>
              <Link href="/about/maker-list">
                <a>
                  <Typography className={classes.link} gutterBottom={true}>
                    掲載塗料メーカー一覧
                  </Typography>
                </a>
              </Link>
              <Typography color="textSecondary" variant="caption">
                {`© ${new Date().getFullYear()} COATAZ`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={3}>
          <Typography variant="h6" gutterBottom={true}>
            COATAZについて
          </Typography>
          <Grid container={true} direction="column">
            <Link href="/about/terms">
              <a>
                <Typography className={classes.link} gutterBottom={true}>
                  利用規約
                </Typography>
              </a>
            </Link>
            <Typography gutterBottom={true}>
              <MaterialUiLink
                color="textPrimary"
                href="https://www.kanamorisangyo.co.jp/privacy"
                underline="hover"
              >
                プライバシーポリシー
              </MaterialUiLink>
            </Typography>
            <Link href="/about/contact">
              <a>
                <Typography className={classes.link} gutterBottom={true}>
                  お問い合わせ
                </Typography>
              </a>
            </Link>
            <Link href="/about/faq">
              <a>
                <Typography className={classes.link} gutterBottom={true}>
                  よくあるご質問
                </Typography>
              </a>
            </Link>
            <Typography gutterBottom={true}>
              <MaterialUiLink
                color="textPrimary"
                href="https://www.kanamorisangyo.co.jp/company/outline"
                underline="hover"
              >
                運営会社
              </MaterialUiLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h6" gutterBottom={true}>
            関連するサービス
          </Typography>
          <Grid container={true} direction="column">
            <Typography gutterBottom={true}>
              <MaterialUiLink color="textPrimary" href="https://plabase.com/" underline="hover">
                プラスチック成形材料DB｜PlaBase
              </MaterialUiLink>
            </Typography>
            <Typography gutterBottom={true}>
              <MaterialUiLink
                color="textPrimary"
                href="https://www.plaquick.com/"
                underline="hover"
              >
                試作開発｜PlaQuick
              </MaterialUiLink>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </StyledStyledTextA>
)

import React from 'react'
import { get } from 'lodash'
import { CardMedia } from '@mui/material/'

export type Thumbnail = {
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string
}

type Image = {
  id: string
  title: string
  thumbnail: Partial<Thumbnail> | null
}

type Props = {
  article: Partial<Image>
  styleImage: any
}

export function Image(props: Props) {
  const image = get(
    props.article,
    'thumbnail.url',
    'https://coataz.s3-ap-northeast-1.amazonaws.com/thumb.png',
  )

  return <CardMedia className={props.styleImage} image={image} title={props.article.title} />
}

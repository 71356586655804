import React from 'react'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import { Typography } from '@mui/material/'

const PREFIX = 'Date'

const classes = {
  captionText: `${PREFIX}-captionText`,
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.captionText}`]: {
    marginRight: theme.spacing(1),
  },
}))

type Date = {
  publishDate?: any
}

type Props = {
  article: Partial<Date>
  fullYear: boolean
}

export const Date = (props: Props) => (
  <StyledTypography color="textSecondary" className={classes.captionText} variant="caption">
    {moment(props.article.publishDate).format(props.fullYear ? 'YYYY/MM/DD' : 'MM/DD HH:mm')}
  </StyledTypography>
)

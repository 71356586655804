import React from 'react'
import { styled } from '@mui/material/styles'
import { Card, CardContent, Grid, Typography } from '@mui/material/'
import {
  useGetAdDistributionManagementQuery,
  AdDistributionManagementFragment,
  AdDeliveryDestination,
} from '@services/graphcms/client'
import dynamic from 'next/dynamic'
import { Image } from '../Article/Image'

const PREFIX = 'AdFeedList'

const classes = {
  card: `${PREFIX}-card`,
  cover: `${PREFIX}-cover`,
  title: `${PREFIX}-title`,
}

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.card}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${classes.cover}`]: {
    height: 0,
    maxHeight: '80px',
    paddingTop: '56.25%', // 16:9
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
}))

const DynamicActionArea = dynamic(import('./ActionArea'), { ssr: false })

type Props = {
  destination: AdDeliveryDestination
}

function FeedListView(props: { ad: AdDistributionManagementFragment }) {
  if (!props.ad.content) {
    return null
  }

  return (
    <CardContent>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Typography className={classes.title} variant="h6">
            {props.ad.content.title}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {`Sponsored by ${props.ad.content.sponsored}`}
          </Typography>
          <Typography component="p" variant="body2">
            {props.ad.content.description}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Image article={props.ad.content} styleImage={classes.cover} />
        </Grid>
      </Grid>
    </CardContent>
  )
}

function AdFeedList(props: Props) {
  const { data } = useGetAdDistributionManagementQuery({
    variables: {
      destination: props.destination,
    },
  })

  if (!data || !data.ad || !data.ad.content) {
    return null
  }

  return (
    <StyledCard className={classes.card} elevation={0} variant="outlined">
      <React.Suspense fallback={<FeedListView ad={data.ad} />}>
        <DynamicActionArea content={data.ad.content} destination={props.destination}>
          <FeedListView ad={data.ad} />
        </DynamicActionArea>
      </React.Suspense>
    </StyledCard>
  )
}

export default AdFeedList

import React from 'react'
import { styled } from '@mui/material/styles'
import dynamic from 'next/dynamic'
import ReactPlayer from 'react-player'
import { Card, CardContent, CardMedia, Typography } from '@mui/material/'
import {
  useGetAdDistributionManagementQuery,
  AdDistributionManagementFragment,
  AdDeliveryDestination,
} from '@services/graphcms/client'
import { Image } from '../Article/Image'

const PREFIX = 'AdFeedCard'

const classes = {
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  title: `${PREFIX}-title`,
}

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    width: '300',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${classes.media}`]: {
    height: 0,
    maxHeight: '400px',
    paddingTop: '56.25%', // 16:9
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
}))

const DynamicActionArea = dynamic(import('./ActionArea'), { ssr: false })

type Props = {
  destination: AdDeliveryDestination
}

function FeedCardView(props: { ad: AdDistributionManagementFragment }) {
  if (!props.ad.content) {
    return null
  }

  return (
    <>
      {props.ad.content.thumbnail?.mimeType === 'video/mp4' ? (
        <CardMedia
          component={ReactPlayer}
          loop={true}
          muted={true}
          playing={true}
          playsinline={true}
          width="100%"
          height="100%"
          url={props.ad.content.thumbnail.url}
        />
      ) : (
        <Image article={props.ad.content} styleImage={classes.media} />
      )}
      <CardContent>
        <Typography className={classes.title} variant="h6">
          {props.ad.content.title}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {`Sponsored by ${props.ad.content.sponsored}`}
        </Typography>
        <Typography component="p" variant="body2">
          {props.ad.content.description}
        </Typography>
      </CardContent>
    </>
  )
}

function AdFeedCard(props: Props) {
  const { data } = useGetAdDistributionManagementQuery({
    variables: {
      destination: props.destination,
    },
  })

  if (!data || !data.ad || !data.ad.content) {
    return null
  }

  return (
    <StyledCard className={classes.card} elevation={0} variant="outlined">
      <React.Suspense fallback={<FeedCardView ad={data.ad} />}>
        <DynamicActionArea content={data.ad.content} destination={props.destination}>
          <FeedCardView ad={data.ad} />
        </DynamicActionArea>
      </React.Suspense>
    </StyledCard>
  )
}

export default AdFeedCard

export const rootCollectionName = {
  articleContacts: 'articleContacts',
  catalogContacts: 'catalogContacts',
  catalogEmailShare: 'catalogEmailShare',
  contacts: 'contacts',
  users: 'users',
} as const

export const subCollectionName = {
  articles: 'articles',
  catalogs: 'catalogs',
  catalogInquiries: 'catalogInquiries',
  companies: 'companies',
  profile: 'profile',
} as const

export const platform = 'coataz'

export const CATALOG_PAGINATION_LENGTH = 16

export const ArticleCategory = {
  factory: 'factory',
  architecture: 'architecture',
  topics: 'topics',
} as const

export const ALGOLIA_INDEX_NAME_ARTICLES = 'articles'

export const ALGOLIA_KANAMORI_CLOUD_INDEX_NAME_CATALOGS = 'catalogs'

import { useEffect, useState } from 'react'

export type Thumbnail = {
  /**
   * Get the url for the asset with provided transformations applied.
   */
  url: string
}

type Image = {
  thumbnail: Partial<Thumbnail> | null
}

export function useIsThumbnail(article: Partial<Image> | null) {
  const [isThumbnail, setIsThumbnail] = useState(article?.thumbnail)
  useEffect(() => {
    setIsThumbnail(article?.thumbnail)
  }, [article?.thumbnail])

  return isThumbnail
}

import React from 'react'
import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material/'
import { useIsThumbnail } from '@hooks/useIsThumbnail'
import { ArticlesFragment } from '@services/graphcms/client'
import { Date } from './Date'
import { Image } from './Image'

const PREFIX = 'FeedList'

const classes = {
  card: `${PREFIX}-card`,
  cover: `${PREFIX}-cover`,
  title: `${PREFIX}-title`,
}

const StyledCard = styled(Card)(() => ({
  [`&.${classes.card}`]: {
    height: '100%',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  [`& .${classes.cover}`]: {
    height: 0,
    maxHeight: '80px',
    paddingTop: '56.25%', // 16:9
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
}))

type Props = {
  article: ArticlesFragment
  fullYear: boolean
  isThumbnail: boolean
  variant: 'elevation' | 'outlined'
}

export function FeedList(props: Props) {
  const router = useRouter()
  const isThumbnail = useIsThumbnail(props.article)

  return (
    <StyledCard className={classes.card} elevation={0} variant={props.variant}>
      <CardActionArea
        onClick={() => router.push(`/article/${props.article.id}`)}
        data-testid="feed-article-list"
      >
        <CardContent>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={isThumbnail ? 8 : 12}>
              <Typography className={classes.title} variant="subtitle1">
                {props.article.title}
              </Typography>
              <Date article={props.article} fullYear={props.fullYear} />
            </Grid>
            {props.isThumbnail && isThumbnail && (
              <Grid item={true} xs={4}>
                <Image article={props.article} styleImage={classes.cover} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  )
}
